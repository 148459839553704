import React from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const TermosCard = () => {

  return(
    <StyledTermosCard>
      <div className="termos">
        <h2 className={font.oL}>Termos e Condições</h2>
        <p className={font.oR}>HCS, Indústria Automóvel Lda, sociedade comercial com sede na Rua Dom João Evangelista de Lima Vidal, 259, 3810-124 Aveiro, com capital social de 10000€ (dez mil euros), registada na Conservatória do Registo Comercial de Aveiro, com o número de matrícula e de pessoa coletiva 514404116.</p>
      </div>
      <div className="politica">
        <h2 className={font.oL}>Política de Privacidade</h2>
        <p className={font.oR}>HCS, Indústria Automóvel Lda, adiante designada por HCS, é responsável pelo site hcsperformance.pt, adiante designado por site HCS, e compromete-se a garantir a privacidade dos dados pessoais recolhidos e/ou transmitidos online. A segurança e a privacidade dos dados dos utilizadores do site HCS são aspetos que assumem para a empresa uma importância crucial.</p>
        <p className={font.oR}>Por favor, leia o texto que se segue para compreender e tomar conhecimento da forma como as informações que disponibilizar serão tratadas pela HCS. A HCS reserva-se ao direito de alterar a política de privacidade, pelo que se aconselha a leitura regular deste documento. Estes textos reproduzem fielmente a política de privacidade seguida pelo site hcsperformance.pt.</p>
        <p className={font.oR}>No presente documento, poderá encontrar a resposta às seguintes questões:</p>
        <ol>
          <li className={font.oR}>Qual é a informação que é recolhida sobre os utilizadores?</li>
          <li className={font.oR}>Qual é a entidade responsável pela recolha da informação?</li>
          <li className={font.oR}>O que são cookies e como é que a HCS os utiliza?</li>
          <li className={font.oR}>Como é que a informação recolhida é utilizada?</li>
          <li className={font.oR}>Que procedimentos de segurança tem o site HCS, que garantam ao utilizador a proteção dos seus dados contra o acesso, a alteração ou a destruição não autorizada?</li>
          <li className={font.oR}>De que forma pode o utilizador corrigir ou eliminar as informações que facultou previamente através do site HCS?</li>
          <li className={font.oR}>Outras questões de que deverá ter conhecimento sobre a política de privacidade da HCS.</li>
        </ol>
        <p className={font.oR}>Se tiver qualquer dúvida sobre alguns dos pontos aqui apresentados, agradecemos que nos comunique por e-mail para rececao@hcsperformance.pt ou por correio para HCS, Rua Dom João Evangelista de Lima Vidal, 259, 3810-124 Aveiro.</p>
        <p className={font.oR}>1. Qual é a informação que é recolhida sobre os utilizadores?</p>
        <p className={font.oR}>Para que se possa tornar um subscritor da nossa newsletter através do nosso site é necessário proceder ao preenchimento do formulário que se encontra disponível online no site hcsperformance.pt. A recolha dos dados pessoais é efetuada no momento do preenchimento do formulário. A informação recolhida é a necessária para assegurar a identidade do utilizador. O fornecimento dos dados solicitados no ato de registo do site da HCS é obrigatório que o utilizador possa usufruir do serviço supramencionado. No ato de registo, a informação que o utilizador disponibiliza tem por objetivo a prestação de um serviço mais adequado às suas características e necessidades e ao envio de informações relacionadas com os serviços prestados pela HCS, tais como: campanhas, eventos ou informações gerais. O leitor poderá atualizar as informações por si fornecidas sempre que quiser bastando que para esse efeito envie o pedido para rececao@hcsperformance.pt. As operações de fornecimento de dados pessoais estão devidamente protegidas. Toda a informação é encriptada e gerida com as mais avançadas ferramentas de segurança.</p>
        <p className={font.oR}>2. Qual é a entidade responsável pela recolha da informação?</p>
        <p className={font.oR}>A HCS é a única responsável pela recolha de dados pessoais efetuada no site HCS. Sempre que o utilizador termine a utilização deste serviço, a HCS não assume qualquer responsabilidade pela informação recolhida fora da respetiva página eletrónica.</p>
        <p className={font.oR}>3. O que são cookies e como é que a HCS os utiliza?</p>
        <p className={font.oR}>Para poder prestar um serviço mais personalizado e ao agrado dos utilizadores, a HCS utiliza cookies para recolher e guardar informação. Um cookie é um pequeno ficheiro informativo que é enviado do servidor do site HCS para o browser do utilizador e armazenado no respetivo computador. Estes cookies serão apenas utilizados pela HCS e a sua utilização limita-se às seguintes finalidades: – Permitir à HCS saber quem é o utilizador e, dessa forma, prestar um serviço melhor, mais seguro e personalizado; - Estimar os níveis de utilização dos serviços. A cada utilizador corresponde um cookie individual, garantindo desta forma a privacidade e a segurança dos dados. A apresentação dos dados estatísticos é feita de uma forma agregada e não individual, não permitindo a identificação do utilizador. Desta forma, é possível avaliar o desempenho do site HCS numa perspetiva de atualização e melhoria constantes, para que se possa satisfazer os gostos e as necessidades do utilizador.</p>
        <p className={font.oR}>4. Como é que a informação recolhida é utilizada?</p>
        <p className={font.oR}>A informação recolhida servirá apenas para os seguintes propósitos: Elaboração de estatísticas que permitam perceber como a HCS poderá melhorar o nível de serviços que presta e, dessa forma, corresponder mais eficazmente às expectativas dos utilizadores; Aumentar o nível de personalização dos serviços para que se adequem, na maior medida possível, aos gostos e preferências dos utilizadores. Os dados pessoais fornecidos pelo utilizador serão utilizados exclusivamente para fins ligados à execução do respetivo contrato, bem como, se o utilizador assim o autorizar, para atividades de informação, marketing e telemarketing da HCS. As comunicações de dados serão feitas mediante prévio consentimento dos titulares dos dados. A HCS não procederá à venda, aluguer, partilha ou oferta dos dados fornecidos pelos seus leitores, exceto nas situações previstas na presente política de privacidade.</p>
        <p className={font.oR}>5. De que forma pode o utilizador corrigir ou eliminar as informações que facultou previamente nas aplicações da HCS?</p>
        <p className={font.oR}>No âmbito das condições de utilização do site HCS e de todos os seus serviços, o utilizador compromete-se a fornecer e a manter atualizados e verdadeiros todos os seus dados pessoais. Por sua vez, a HCS garante ao utilizador a possibilidade de corrigir e atualizar os seus dados, devendo o utilizador contactar a HCS por e-mail para rececao@hcsperformance.pt ou via postal, HCS, Rua Dom João Evangelista de Lima Vidal, 259, 3810-124 Aveiro. Todos os utilizadores poderão, através do website HCS subscrever newsletters. Em todas as newsletters enviadas aos subscritos do website HCS têm a opção de cancelamento da subscrição.</p>
        <p className={font.oR}>6. Outras questões de que deverá ter conhecimento sobre a política de privacidade do website HCS:</p>
        <p className={font.oR}>– Alteração de Condições de Utilização do site da HCS<br/>Sempre que houver uma alteração das condições de utilização do site HCS, designadamente nas Condições de Serviço ou na Política de Privacidade, a HCS enviará um email aos seus utilizadores.</p>
        <p className={font.oR}>– Autorização para Utilizações Diversas<br/>Se a HCS pretender utilizar os dados dos seus utilizadores para finalidades distintas daquelas para as quais recebeu autorização inicial dos utilizadores, solicitará uma nova autorização dos utilizadores para essa utilização específica.</p>
      </div>
      <div className="comissao">
        <h2 className={font.oL}>Comissão Nacional de Proteção de Dados</h2>
        <p className={font.oR}>O titular dos dados poderá apresentar uma reclamação à autoridade de controlo (CNPD). Contudo, caso pretenda proceder a essa reclamação, não hesite em contactar a HCS numa primeira fase, através do e-mail rececao@hcsperformance.pt.</p>
      </div>
    </StyledTermosCard>
  )
}

export default TermosCard

const StyledTermosCard = styled.div`
background-color: transparent !important;
  position: relative;
  padding: 120px 0 60px;
  .termos, .politica, .comissao{
    margin: auto;
    width: 60%;
    min-width: 300px;
  }
  h2,p{margin: 2em 0;}
  li{margin: 1em 0;}
`